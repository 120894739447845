'use client';

import React, { ReactElement, useContext } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';
import { Typography, Button2 as Button, Icon } from '@vaimo-int/aem-pwa-common-components';
import Mask from '@/components/Mask';
import FilterBlock from '../FilterBlock';
import { useFilterSidebar } from './hooks/useFilterSidebar';
import { FILTER_SIDEBAR_REF } from '@/constants/refIds';
import { LogoLoading } from '@/components/LoadingIndicator';

import classes from './filterSidebar.module.scss';

const FilterSidebar = (): ReactElement | null => {
    const { fhrId, filters, isPending, search, startTransition, totalCount } = useContext(CategoryContext);

    const t = useTranslations('filterBar');
    const tCategory = useTranslations('categoryContent');
    const tGlobal = useTranslations('global');

    const { handleScrollToFilterBar, isFilterModalOpen, isFilterSidebarOpen, toggleFilterButton } = useFilterSidebar();

    if (!filters?.length) return null;

    return (
        <>
            <Mask isActive={isFilterModalOpen} dismiss={toggleFilterButton} classes={{ root_active: classes.mask }} />
            <aside
                className={clsx(
                    classes.sidebar,
                    isFilterSidebarOpen && classes.sidebarOpen,
                    isFilterModalOpen && classes.sidebarModalOpen,
                )}
                id={FILTER_SIDEBAR_REF}
            >
                <div className={classes.top}>
                    <div className={classes.topHeader}>
                        <Typography.Heading style={'h2'}>{tCategory('filter')}</Typography.Heading>
                        <Button onClick={toggleFilterButton} variant={'trigger'} aria-label={tGlobal('close')}>
                            <Icon name={'close'} size={22} onClick={toggleFilterButton} />
                        </Button>
                    </div>
                    <div className={classes.totalItemsLabel}>
                        <Typography.Body style={'bold'}>{totalCount}</Typography.Body>
                        <Typography.Body>{totalCount === 1 ? t('singleItem') : t('newTotalItems')}</Typography.Body>
                    </div>
                </div>
                <div className={classes.content}>
                    {isFilterModalOpen && isPending ? (
                        <div className={classes.contentLoader}>
                            <LogoLoading />
                        </div>
                    ) : null}
                    <FilterBlock
                        fhrId={fhrId}
                        filters={filters}
                        handleScrollToFilterBar={handleScrollToFilterBar}
                        isPending={isPending}
                        search={search}
                        startTransition={startTransition}
                    />
                </div>
            </aside>
        </>
    );
};

export default FilterSidebar;
