import { useContext, useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { convertObjectToSearchParams, getSearchFromState, getStateFromSearch, stripHtml } from '../utils/helpers';
import { useSelectFilter } from '@vaimo/google-tag-manager';
import { useFredHopper } from '@/components/RootComponents/Category/hooks/useFredHopper';
import type { IFilterBlockProps, ItemType } from '@/components/RootComponents/Category/modules/FilterBar/types';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';

const PARAMS_LIST = ['q', 'ct'];
export const useFilterBlock = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    isPending,
    search,
    startTransition,
}: IFilterBlockProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = convertObjectToSearchParams(search);
    const { selectFilter } = useSelectFilter();
    const { commonData, getFacets } = useFredHopper(fhrId);
    const { faceted_navigation } = useContext(CategoryContext);
    const [filterState, setFilterState] = useState(new Map());

    const { itemsByGroup, keys, names } = useMemo(() => {
        const names = new Map();
        const keys = new Set();
        const itemsByGroup = new Map();

        if (filters?.length) {
            for (const filter of filters) {
                const { attribute_code: group, label: name, options } = filter;

                const items = [];
                names.set(group, name);
                keys.add(group);

                for (const { count, default_label, label, value, value_id } of options) {
                    items.push({
                        color: group === 'color_group' && (default_label || label),
                        count,
                        title: stripHtml(label),
                        value,
                        value_id,
                    });
                }
                itemsByGroup.set(group, items);
            }
        }

        return { itemsByGroup, keys, names };
    }, [filters]);

    useEffect(() => {
        const nextState = getStateFromSearch(searchParams?.toString() || '', keys, itemsByGroup);

        setFilterState(nextState);
    }, [keys, itemsByGroup]);

    const getFacetNavigationList = (): Array<string> => {
        if (!faceted_navigation) {
            return [];
        }
        if (filterState.size > 0) {
            return [];
        }

        return faceted_navigation.split(',');
    };

    const handleReset = () => {
        const params = convertObjectToSearchParams(search);

        Object.keys(search || {}).map((key) => {
            if (!PARAMS_LIST.includes(key)) {
                params.delete(key);
            }
        });

        params.set('p', '1');

        startTransition(() => router.push(`${pathname}?${params.toString()}`, { scroll: false }));
        handleScrollToFilterBar();
    };

    const getNextState = (group: string, item: ItemType) => {
        const nextState = new Map(filterState);
        const nextSet = new Set(filterState.get(group));
        const params = convertObjectToSearchParams(search);

        if (params?.has('p')) {
            params.delete('p');
        }

        if (nextSet.has(item)) {
            nextSet.delete(item);
        } else {
            nextSet.add(item);
        }

        if (nextSet.size) {
            nextState.set(group, nextSet);
        } else {
            nextState.delete(group);
        }

        return nextState;
    };

    const getNextSearchQuery = (group: string, item: ItemType): string => {
        const nextState = getNextState(group, item);

        return getSearchFromState(convertObjectToSearchParams(search).toString(), keys, nextState);
    };

    const handleClickOnItem = (group: string, item: ItemType) => {
        const nextState = getNextState(group, item);
        const nextSearch = getNextSearchQuery(group, item);

        selectFilter({
            ...commonData,
            ...getFacets({
                filtersData: filters,
                nextSearch,
            }),
        });

        setFilterState(nextState);

        if (!isPending) {
            startTransition(() => {
                router.push(pathname + nextSearch, { scroll: false });
            });
        } else {
            router.replace(pathname + nextSearch, { scroll: false });
        }

        handleScrollToFilterBar();
    };

    return {
        facetNavigationList: getFacetNavigationList(),
        filterItems: itemsByGroup,
        filterKeys: keys,
        filterNames: names,
        filterState,
        getNextSearchQuery,
        handleClickOnItem,
        handleReset,
    };
};
