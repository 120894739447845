'use client';

import React, { ReactElement, useContext } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';
import { Typography, Icon, Button2 as Button } from '@vaimo-int/aem-pwa-common-components';
import Container from '@/components/Container';
import { useFilterBar } from './hooks/useFilterBar';
import FilterSort from '../FilterSort';
import { FILTER_BAR_REF } from '@/constants/refIds';
import type { IFilterBarProps } from './types';

import classes from './filterBar.module.scss';

const FilterBar = ({ options }: IFilterBarProps): ReactElement => {
    const { filters, search, totalCount } = useContext(CategoryContext);
    const t = useTranslations('filterBar');
    const {
        handleScrollToFilterBar,
        isFilterBarVisible,
        isFilterModalOpen,
        isFilterSidebarOpen,
        isStickyBar,
        toggleFilterButton,
        totalFiltersCount,
    } = useFilterBar({
        filters,
        search,
    });

    return (
        <aside
            className={clsx(classes.root, {
                [classes.rootHide]: !isFilterBarVisible,
                [classes.rootOpen]: isFilterModalOpen,
                [classes.rootSticky]: isStickyBar,
            })}
            id={FILTER_BAR_REF}
            data-mt-type="filter-bar"
        >
            <Container className={classes.container}>
                <div>
                    {filters?.length ? (
                        <Button classes={classes.button} onClick={toggleFilterButton} variant={'trigger'}>
                            <span
                                className={clsx(classes.buttonOpenSidebar, isFilterSidebarOpen && classes.buttonShow)}
                            >
                                {t('buttonHideText')}
                                <Icon name={'close'} size={18} />
                            </span>
                            <span
                                className={clsx(classes.buttonCloseSidebar, isFilterSidebarOpen && classes.buttonHide)}
                            >
                                <span className={classes.filterButton}>
                                    <span className={classes.buttonText}>{t('buttonShowText')}</span>
                                    <span className={classes.buttonHide}>{t('newButtonText')}</span>
                                    {totalFiltersCount ? <span>({totalFiltersCount})</span> : null}
                                </span>
                                <Icon name={'filter'} size={18} />
                            </span>
                        </Button>
                    ) : null}
                </div>
                <div className={classes.totalItemsLabel}>
                    <Typography.Body style={'bold'}>{totalCount}</Typography.Body>
                    <Typography.Body>{totalCount === 1 ? t('singleItem') : t('newTotalItems')}</Typography.Body>
                </div>
                <FilterSort options={options} handleScrollToFilterBar={handleScrollToFilterBar} />
            </Container>
        </aside>
    );
};

export default FilterBar;
