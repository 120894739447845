import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Typography } from '@vaimo-int/aem-pwa-common-components';
import FilterItem from '../FilterItem';
import { COLOR_GROUP, SIZE_GROUP } from '@/constants/listingData';
import type { ItemType } from '../FilterBar/types';

import classes from './filterList.module.scss';

const GENERAL_OPTIONS_SIZE = 5;
const GROUP_OPTIONS_SIZE = 9;

interface IFilterList {
    filterState: Set<object>;
    getNextSearchQuery: (group: string, item: ItemType) => string;
    group: string;
    handleClick: (group: string, item: ItemType) => void;
    isFacetNavigationEnabled: boolean;
    items: ItemType[];
}

const FilterList = ({
    filterState,
    getNextSearchQuery,
    group,
    handleClick,
    isFacetNavigationEnabled,
    items,
}: IFilterList) => {
    const t = useTranslations('filterList');
    const [isListExpanded, setIsListExpanded] = useState(false);
    const filtersSize = group === SIZE_GROUP || group === COLOR_GROUP ? GROUP_OPTIONS_SIZE : GENERAL_OPTIONS_SIZE;
    const sliceLength = isListExpanded ? items.length : filtersSize;

    const itemsList = items.slice(0, sliceLength);
    const checkSelectedItem = !!items?.slice(filtersSize).find((item) => filterState?.has(item));

    useEffect(() => {
        if (filterState?.size && checkSelectedItem) {
            setIsListExpanded(true);
        }
    }, [filterState?.size, checkSelectedItem]);

    return (
        <>
            <div
                className={clsx(classes.filterItems, {
                    [classes.filterItemsColors]: group === COLOR_GROUP,
                    [classes.filterItemsSizes]: group === SIZE_GROUP,
                })}
            >
                {itemsList.map((item: any, index: number) => (
                    <FilterItem
                        key={`item-${group}-${index}`}
                        filterState={filterState}
                        group={group}
                        item={item}
                        isFacetNavigationEnabled={isFacetNavigationEnabled}
                        getNextSearchQuery={getNextSearchQuery}
                        handleClick={handleClick}
                    />
                ))}
            </div>
            {items.length > filtersSize && (
                <button
                    onClick={() => setIsListExpanded(!isListExpanded)}
                    type="button"
                    className={classes.viewMoreButton}
                >
                    <Typography.Body className={classes.buttonText}>
                        {isListExpanded ? t('viewLess') : t('viewMore')}
                    </Typography.Body>
                </button>
            )}
        </>
    );
};

export default FilterList;
